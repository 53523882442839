import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`

export interface Props {
  title: string
  path: string
  description: string
  image?: string
  ogTags?: Record<string, string>
}

export const SEO: React.FC<Props> = ({
  title,
  path,
  image,
  description,
  ogTags,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  return (
    <Helmet defer={false} title={`${title} - ${siteMetadata.title}`}>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`${siteMetadata.siteUrl}${path}`} />
      <meta property="og:description" content={description} />
      {image && (
        <meta property="og:image" content={`${siteMetadata.siteUrl}${image}`} />
      )}
      {ogTags &&
        Object.entries(ogTags).map(([key, value]) => (
          <meta key={key} property={key} content={value} />
        ))}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Roboto+Mono:wght@400;700&family=Roboto:wght@300;400;700&display=swap"
      />
    </Helmet>
  )
}
