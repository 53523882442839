import React from "react"
import { Props as SEOProps, SEO } from "./SEO"
import { Link } from "gatsby"
import { Container } from "./Container"
import linkedInGrey from "../images/linkedin_grey.svg"
import linkedIn from "../images/linkedin.svg"

export interface Props {
  seo: SEOProps
  hero?: true
}

export const Layout: React.FC<Props> = ({ children, seo, hero }) => (
  <div className="layout">
    <SEO {...seo} />
    {hero && (
      <div className="hero">
        <div className="background" />
        <div className="logo" />
        <div className="content">
          <h1>Naar een energieneutrale toekomst!</h1>
          <p>
            Als Nederland hebben we ambitieuze doelen voor duurzame energie en
            een duurzame toekomst. De enige manier waarop we deze doelen kunnen
            bereiken is samen. Wij kunnen je daarbij helpen.
          </p>
          <Link to="/contact" className="green button">
            Aan de slag!
          </Link>
        </div>
      </div>
    )}
    <div className="page">
      <nav>
        <Container>
          <Link to="/" className="logo" />
          <div className="links">
            <Link to="/contact">Contact</Link>
            <a
              href="https://www.linkedin.com/in/arno-van-drenth-a69799153/"
              target="_blank"
            >
              <img
                style={{ width: 24, height: 24 }}
                src={linkedInGrey}
                alt="LinkedIn"
              />
            </a>
          </div>
        </Container>
      </nav>
      <main>{children}</main>
      <footer>
        <Container>
          <div>
            <p>Enervar</p>
            <p>
              Detmarlaan 35
              <br />
              6711MP Ede
            </p>
            <a href="mailto:info@enervar.nl">info@enervar.nl</a>
          </div>
          <div>
            <p>KVK: 78140080</p>
            <p>BTW: NL003290497B88</p>
          </div>
          <div></div>
          <div className="social-links">
            <a
              href="https://www.linkedin.com/in/arno-van-drenth-a69799153/"
              target="_blank"
            >
              <img
                style={{ width: 24, height: 24, fill: "white" }}
                src={linkedIn}
                alt="LinkedIn"
              />
            </a>
          </div>
        </Container>
      </footer>
    </div>
  </div>
)
